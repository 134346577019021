var SearchHelper = function(elementId, submitBtnId, prompt) {
  var element_id = elementId;
  var element = $(elementId);
  var prompt_text = prompt || "";
  var searched_user_text = "";
  var submit_button = $(submitBtnId);

  if(element.value == '' || element.value == prompt_text) {
    element.addClassName('prompt');
    element.value = prompt_text;
  };

  Event.observe(element_id, 'blur', function(event) {
    if(element.value == '') {
      element.addClassName('prompt');
      element.value = prompt_text;
    } else if(element.value != prompt_text) {
      element.removeClassName('prompt');
    }
  });

  Event.observe(element_id, 'focus', function(event) {
    if(element.value == prompt_text) {
      element.value = '';
      element.removeClassName('prompt');
    }
    element.select();
  });

  jQuery('#' + element_id).ready(function(){
    jQuery(this).change( function() {
      if(element.value == '' && searched_user_text != '') {
        submit_button.click();
      }
    });
  });

  var resetSearchPrompt = function() {
    if(element.value == '') {
      element.addClassName('prompt');
      element.value = prompt_text;
    }
  };

  var setSearchedText = function() {
    searched_user_text = element.value;
  };
 
  return {
    reset_search_prompt : resetSearchPrompt,
    set_searched_text : setSearchedText
  }
};
