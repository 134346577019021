confirmChangeRemoveCustomDomain = function(currentCustomDomain, companySsl, buttonId){
  var currentCustomDomainPresent = false
  var companySslPresent = companySsl ? companySsl : false
  if (currentCustomDomain != null && currentCustomDomain != undefined && currentCustomDomain != '') {
    currentCustomDomainPresent = true
  }
  if(currentCustomDomainPresent && jQuery('#company_custom_domain').val().strip('').length == 0){
    // confirmAction('If you remove your custom domain you cannot use it any more to access your site. Are you sure you want to remove your custom domain?', null, buttonId, toggleWaitBox(true));
    if (confirm('If you remove your custom domain you cannot use it any more to access your site. Are you sure you want to remove your custom domain?')) {
      jQuery('#'+ buttonId).click();
    }
  } else if (currentCustomDomainPresent && companySslPresent && jQuery('#company_custom_domain').val().strip('') != currentCustomDomain){
    // confirmAction('If you change your custom domain you cannot use HTTPS until you upload a new certificate. Are you sure you want to change your custom domain?', null, buttonId, toggleWaitBox(true));
    if (confirm('If you change your custom domain you cannot use HTTPS until you upload a new certificate. Are you sure you want to change your custom domain?')) {
      jQuery('#'+ buttonId).click();
    }
  } else {
    toggleWaitBox(true);
    jQuery('#'+ buttonId).click();
  }
}