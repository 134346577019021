initTrunk8 = function(parent) {
  stringTrunk8(parent);
  stringTrunk8Static();
  initTrunk8Faded('.string_trunk8_no_tooltip', false) // Truncates without tooltip by fading the last two characters.
}

stringTrunk8 = function(parent){
  if(!parent)
    parent = "";
  var elements_class = parent + " .string_trunk8_faded" + "," + parent + " .string_trunk8"
  initTrunk8Faded(elements_class); // Truncates by fading the last two characters.
}

stringTrunk8Static = function(){
  initTrunk8Faded('.string_trunk8_static', true)
  initTrunk8Faded('.scale_labels, .string_trunk8_second_line', true, 2)
}

initTrunk8Faded = function(elements_class, tooltip, lines) {
  jQuery(elements_class).each(function(i){
    var tr_ele = jQuery(this);
    tr_ele.trunk8({tooltip: tooltip, lines: lines, fill: '',
      onTruncate: function() {
        FadedTruncation(tr_ele);
      }
    });
  });
}

FadedTruncation = function(tr_ele){
  var truncated_str = tr_ele.text();
  if(truncated_str)
  {
    var truncated_length = truncated_str.length;
    var full_string_length = tr_ele.data('trunk8').original_text.length;

    if(full_string_length > truncated_length) {
      var fill = truncated_str.substr(truncated_length-3, 3);
      var new_text = truncated_str.substr(0, truncated_length-3);
      var faded_text = '<span class="fade1">' +fill[0]+ '</span>' + '<span class="fade2">' +fill[1]+ '</span>'+ '<span class="fade3">' +fill[2]+ '</span>';
      tr_ele.html(new_text + faded_text);
    }
  }
}

confirmAction = function (msg ,actionLink , buttonId, fromSinglepage = false){
  var btn_class_cancel = fromSinglepage ? 'cancel no_underline confirm-btn-cancel' : 'confirmCancel ';
  var btn_class_ok = fromSinglepage ? 'font_open_sans_semibold_10_blue btn btn-default btn-round-sm confirm-btn-ok' : 'confirmOk ';
  var confirm_box_width = fromSinglepage ? '420px' : '350px';
  var jc = jQuery.confirm({
     title: false,
     content: msg,
     boxWidth: confirm_box_width,
     theme: 'material',
     bgOpacity: 0,
     scrollToPreviousElement: false,
     backgroundDismiss: true,
     backgroundDismissAnimation: 'none',
     buttons: {
        cancel: { 
            btnClass: btn_class_cancel,
            keys : ['escape'],
            action :function(){}
        }, 
        ok: {
            btnClass: btn_class_ok,
            keys : ['enter'],
            action:function(){
              if(actionLink) {
                jQuery('#'+ actionLink).find('a').first().trigger('click');
              }
              else {
                jQuery('#'+ buttonId).trigger('click');    
              }
            },
        }, 
     },
  });
}

checkForFlashAndNotify = function (msg_holder_element) {
  if(isFlashInstalled()) {
    return true;
  } else {
    notifyNoFlash(msg_holder_element);
    return false;
  }
}

isFlashInstalled = function () {
  return (swfobject.hasFlashPlayerVersion("10.3.0") ? true : false);
}

adjustVjsPlayerContainerHeight = function (container_div, adjustment) {
  if(container_div == null)
    container_div = 'player_preview';
  if(adjustment == null)
    adjustment = 0;

  jQuery('#' + container_div).each(function() {
    var h = jQuery(this).parent().height() - adjustment;
    var w = jQuery(this).parent().width();
    jQuery(this).height(h);
    jQuery(this).width(w);
  });
}

showHideLastContentNotice = function (show_flag, show_sugg) {
  var show_suggestion = show_sugg || false;
  var from_single_page_ui = (typeof(from_single_page) != 'undefined') ? from_single_page : false;
  var notice = $('.last_content_notice').first();
  var selector = from_single_page_ui ? '.content_thumbnail' : '.thumbnail';
  if(!notice) {
    return;
  }
  var show_notice  = ($(selector).length > 1 || (show_suggestion && jQuery('#showSuggestionsLink').length > 0)) ? show_flag : false // Checking the number of contents and displaying the notice if number of contents is greater than 1
  if(show_notice) {
    notice.show();
    // notice.appear({duration: 0.25});
    //Effect.SlideUp(notice, {duration: 0.25});
  } else {
    if (notice.is(':visible')) {
      notice.hide();
      // notice.fade({duration: 0.25});
    }
    //Effect.SlideDown(notice, {duration: 0.25});
  }
}

selectNextContent = function (skip_suggestions , is_tincan_scorm) {
  // var from_single_page_ui = (typeof(from_single_page) != 'undefined') ? from_single_page : false;
  var from_single_page_ui = true
  var thumbnails = $(".playlist_container .content_thumbnail"),
      itemToSelect = null,
      index = 0,
      slidesVisible = 6,
      courseId = jQuery('#course_id').val();
  if(arguments.length == 0) {
    skip_suggestions = false;
  }

  var selectNext = function() {
    thumbnails.each(function(e) {
      if($(this).hasClass('selected')) {
        if(index == thumbnails.length - 1) {
          itemToSelect = thumbnails[0];
        } else {
          itemToSelect = thumbnails[index + 1];
        }
        return;
      }
      index++;
    });
    if(index > slidesVisible - 1 && index%(slidesVisible - 1) == 1) {
      try {
        content_slider.prev();
      } catch(err) {
        //eat the error
      }
    }
    if(itemToSelect) {
      if(from_single_page_ui) {
        itemToSelect.click();
      } else {
        itemToSelect.onclick();
      }
    }
  };

  var isLastThumbnail = false,
      _index = 0;
  thumbnails.each(function(e) {
    if($(this).hasClass('selected')) {
      if(_index == thumbnails.length - 1) {
        isLastThumbnail = true;
      }
      return;
    }
    _index++;
  });

  if(isLastThumbnail && !skip_suggestions) {
    jQuery.ajax({
        type: 'GET',
        url: '/courses/' + courseId.toString() + '/needs_suggestions.json',
        data: { is_tincan_scorm : is_tincan_scorm},
        dataType: 'json',
        success: function(response) {
            if(response['suggest']) {
              $('showSuggestionsLink').onclick();
            } else {
              selectNext();
            }
        }
    });
  } else {
    selectNext();
  }
}

guid = function () {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random()*16|0, v = c == 'x' ? r : (r&0x3|0x8);
    return v.toString(16);
  });
}

closeDescriptionPopup = function () {
  jQuery('.expanded_description').slideUp(0);
  jQuery('.truncated_description').show(0, showHideNicescroll("course_full_description", "hide"));
}

showHideNicescroll = function (element_id, action){
  if(action == 'hide'){
    jQuery('#' + element_id).getNiceScroll().hide();
  }else if(action == 'show'){
    jQuery('#' + element_id).getNiceScroll().show();
  }
}

InitializeSessionData = function (scorm_package_version,rt_data) {
  if(scorm_package_version == "1.2"){
    Runtime12_InitializeSessionData(rt_data);
  }else {
    Runtime_InitializeSessionData(rt_data);
  }
}

InitializeRunTimeApi = function (){
  var API_1484_11 = null;
  var API_1484_11 = new RunTimeApi();
  rtApi = API_1484_11;
  window.API_1484_11 = API_1484_11;
  var API = null;
  var API = new RunTime12Api();
  window.API = API;
}

mceWebTextContentCallback = function (editor) {
  initVideoPlayer(editor);
  setTargetBlankForHrefContents(editor);
  setUrlBaseForEmbeddedContent(editor);
}

mceWebTextContentCallbackContentPlayer = function (editor) {
  initVideoPlayer(editor);
  detectEndOfWebTextContent(editor);
  setTargetBlankForHrefContents(editor);
  setUrlBaseForEmbeddedContent(editor);
}

initVideoPlayer = function (iframe) {
  var iframe_id = iframe.id + '_ifr';
  var video_datas = retriveVideodata(iframe_id, "mcevideoembed");

  loadVideoJsPlayer(iframe_id, video_datas);
}

loadVideoJsPlayer = function (iframe_id, video_datas) {
  var iFrameID = document.getElementById(iframe_id);
  iFrameID.setAttribute("allowfullscreen", true);

  //Inserts the necessary css and js files to the head of the Iframe.
  includeExternalCSS(iFrameID.contentDocument, iframe_id, "/video-js/dist/video-js.css");
  js = includeExternalJS(iFrameID.contentDocument, iframe_id, "/javascripts/video_packaged.js");
  js.onload = function() {
    // script successfully loaded
    if(video_datas.length > 0){
      sendRequestToRetrieveVideoUrl(iframe_id, video_datas, true);
    } else{
      displayHtmlVideoPlayer(iFrameID);
    }
  }
}

sendRequestToRetrieveVideoUrl = function (iframe_id, video_datas, htmlvideo){
  var custom_video_tags = jQuery("#" + iframe_id).contents().find("mcevideoembed");
  for(var i = 0; i < video_datas.length; i++) {
    if((video_datas[i].id != "") && (video_datas[i].class_name != "")) {
      displayLoader(custom_video_tags, i);
      new Ajax.Request(
      '/posts/video_url',{
        method: 'get',
        parameters: 'id='+ video_datas[i].id + '&class_name=' + video_datas[i].class_name + '&position=' + i + '&iframe_id=' + iframe_id + '&htmlvideo=' + htmlvideo
      });
    }
  }
}

displayLoader = function (custom_tags, place){
  var custom_tag = custom_tags[place];
  var loader_tag = jQuery(custom_tag.parentNode);
  var player = loader_tag.next();
  var loader = new Element('img', {src: '/images/loader.gif'});
  if(loader_tag.find('img').length == 0) {
    loader_tag[0].appendChild(loader);
  }
  loader_tag.parent().attr("style","width:540px; height:429px");
  loader_tag.attr("style","position:relative; top:50%; left:50%; width:20px");
  loader_tag.show();
  player.hide();
}

includeExternalJS = function (htmlContent, iframe_id, src) {
  jsTag = htmlContent.createElement("script");
  jsTag.src = src;
  jsTag.type = "text/javascript";
  jQuery("#" + iframe_id).contents().find("head")[0].appendChild(jsTag);
  return jsTag;
}

includeExternalCSS = function (htmlContent, iframe_id, src) {
  cssLink = htmlContent.createElement("link");
  cssLink.href = src;
  cssLink.rel = "stylesheet";
  cssLink.type = "text/css";
  jQuery("#" + iframe_id).contents().find("head")[0].appendChild(cssLink);
}

displayHtmlVideoPlayer = function (iFrameID){
  var video_objects = iFrameID.contentDocument.getElementsByClassName('video-js');
  var flash_objects = iFrameID.contentDocument.getElementsByName('flash_vid');

  for(var i = 0; i < video_objects.length; i++){

    //Display the video js player and Hide jwPlayer
    var videojs_wrapper_div = video_objects[i].parentElement
    var jwplayer_wrapper_div = flash_objects[i].parentElement
    videojs_wrapper_div.style.display = ''
    videojs_wrapper_div.style.position = "relative";
    jwplayer_wrapper_div.style.display = "none";

    //Builds the UI and attaches the events of VideoJS.
    iFrameID.contentWindow.videojs(video_objects[i].id, { "controls": true, "autoplay": false, "preload": "auto", "techOrder":["html5", "flash"],"ytcontrols":false }, function(){
      if(jQuery("#"+iFrameID.id).closest("div").getNiceScroll().length != 0) {
        jQuery("#"+iFrameID.id).closest("div").getNiceScroll().resize();
      }
    });
  }
}

retriveVideodata = function (iframe_id, tag_name){
  var datas = [];
  var video_embed_tags = jQuery("#" + iframe_id).contents().find(tag_name);
  for(var i = 0; i < video_embed_tags.length; i++){
    var id = video_embed_tags[i].attributes["data-attr-id"].value;
    var class_name = video_embed_tags[i].attributes["data-attr-class"].value;
    datas.push({'id': id, 'class_name': class_name});
  }
  return datas;

}
TinymceInitialize = function(width,height,selector='.tinymce') {
	tinymce.init({
    selector: "raw_data",
    forced_root_block:false,
    convert_fonts_to_spans : false,
    selector: selector,
    plugins: "save",
    toolbar: "save",
    width: width,
    height: height,
    menubar: false,
    plugins: "advlist",
    plugins: 'tinydrive',
    plugins: "media",
    menubar: "insert",
    toolbar: "media",
    plugins: [ 'quickbars' ],
    plugins: [
    'advlist autolink lists link image charmap print preview anchor',
    'searchreplace visualblocks code fullscreen',
    'insertdatetime media table paste code help wordcount'
    ],
    toolbar: 'undo redo | formatselect | ' +
    ' bold italic backcolor | alignleft aligncenter ' +
    ' alignright alignjustify | bullist numlist outdent indent |' +
    ' removeformat | help' + 'media'
  });
}

registerDateTimePair = function (options) {
  var dtp = new DateTimePair({startDate: options.startDate,
            startTime: options.startTime,
            endDate: options.endDate,
            endTime: options.endTime});

  dtp.updateDifference();

  $(options.startDate).onchange = function(){
    dtp.syncEndDateTime();
  };

  $(options.startTime).onchange = function(){
    dtp.syncEndDateTime();
  };

  $(options.endDate).onchange = function(){
    dtp.updateDifference();
  };

  $(options.endTime).onchange = function(){
    dtp.updateDifference();
  };
  
  if(options.duration != null && options.duration != undefined){
    jQuery('select#'+options.duration).bind("change",function(){
      dtp.updateDelta(this.value);
      dtp.syncEndDateTime();
    });
  }
}

var DateTimePair = function(options) {
  var startDateInput = jQuery('#' + options.startDate);
  var startTimeInput = jQuery('#' + options.startTime);
  var endDateInput = jQuery('#' + options.endDate);
  var endTimeInput = jQuery('#' + options.endTime);
  var timeDelta = 0;
  var startDateText,
  startTimeText,
  endDateText,
  endTimeText,
  startDate,
  endDate;

  return {
    updateDifference: function() {
      if (startDateInput.length && endDateInput.length) {
        startDateText = startDateInput.val();
        startTimeText = startTimeInput.val();
        endDateText = endDateInput.val();
        endTimeText = endTimeInput.val();
        if (startDateText.length > 0 && startTimeText.length > 0 && endDateText.length > 0 && endTimeText.length > 0){
          startDate = new Date(startDateText + " " + startTimeText);
          endDate =  new Date(endDateText + " " + endTimeText);

          timeDelta = (endDate - startDate)/1000;
        }
      }
    },

    delta: function() {
      return timeDelta;
    },
            
    updateDelta: function(delta_value){
      timeDelta = delta_value;
    },

    syncEndDateTime: function() {
      if(timeDelta == 0 ) {
        return;
      }

      if (startDateInput.length && endDateInput.length) {

        startDateText = startDateInput.val();
        startTimeText = startTimeInput.val();
        endDateText = endDateInput.val();
        endTimeText = endTimeInput.val();
        if (startDateText.length > 0 && startTimeText.length > 0 && endDateText.length > 0 && endTimeText.length > 0){
          startDate = new Date(startDateText + " " + startTimeText);
          endDate =  new Date(endDateText + " " + endTimeText);
          if (!isNaN(startDate) && !isNaN(endDate)) {
            var newEndDate = startDate.add({
              seconds: timeDelta
            });
            endDateInput.val(newEndDate.toString(DATEPICKER_FORMAT));
            endTimeInput.val(newEndDate.toString(TIMEPICKER_FORMAT).toLowerCase());
          }
        }
      }
    }
  }


}

removeTinyMCEEditor = function (editor_id) {
  var editor = tinyMCE.editors[editor_id];
  if (editor) {
    tinyMCE.remove(editor);
  }
}

update_record_changed_field = function (initial_val, field_id, id) {
  var current_val = jQuery("#"+field_id).val();
  current_val = isNaN(parseFloat(current_val)) ? current_val : parseFloat(current_val)
  if(initial_val.toString() != current_val.toString()){
    jQuery('#' + id).val(true);
  } else {
    jQuery("#" + id).val(false);
  }
}

alignPriceScheduleLinks = function (member_pricing, product_ids) {
  product_ids.each(function(product_id) {
    if(member_pricing) {
      positionLinks('member_price_schedule_link_', 'product_'+product_id+'_member_price', product_id)
    }
    positionLinks('price_schedule_link_', 'product_'+product_id+'_price', product_id)
  });
}

positionLinks = function (link_id, element_id, product_id) {
  var top_position = jQuery("."+element_id).offset().top + 1;
  var left_position = jQuery("."+element_id).offset().left + 56;
  jQuery("#"+link_id+product_id).offset({top: top_position, left: left_position});
  jQuery("#"+link_id+"container_"+product_id).show();
}

positionDateSelector = function (id, scroll_content_id){
  jQuery("#" + id).on("click", function(){
    var top = jQuery("#" + id).offset().top;
    var height = jQuery("#" + id).height();

    jQuery(".calendar_date_select").css("top", top + height);
    jQuery(".ie6_blocker").css("top", top + height);
  });

  jQuery(window).resize(function(){
    jQuery(".calendar_date_select").hide();
    jQuery(".ie6_blocker").hide();
    jQuery("#" + id).blur();
  });
  
  jQuery("#" + scroll_content_id).scroll(function(){
    jQuery(".calendar_date_select").hide();
    jQuery(".time-picker").hide();
    jQuery(".ie6_blocker").hide();
    jQuery("#" + id).blur();
  });
}

applySelectedCoupons = function (elementToUpdate, product_id, product_type) {
  elementToUpdate = $('#' + elementToUpdate);
  elementToUpdate.innerHTML = '';
  var codes = [], codeIds = [];
  var index = 0;
  jQuery('#coupon_selected_list li').each(function(li) {
    li = $('#coupon_selected_list li')[li]
    var input = li.querySelectorAll('input')[0];
    if (input) {
      var code = li.querySelectorAll('label')[0].innerHTML;
      ////console.log(input.value);
      codes[index] = code;
      codeIds[index] = input.value;
      index++;
    }
  });
  elementToUpdate[0].innerHTML = codes.join(' ');
  jQuery('#' + product_type + '_coupons_' + product_id.toString())[0].value = codeIds.join(' ');
  jQuery('#' + product_type + '_coupon_codes_' + product_id.toString())[0].value = codes.join(' ');
  jQuery('#coupons_for_course_' + product_id.toString()).toggle()
  jQuery('#coupons_for_company_' + product_id.toString() + '_content').toggle()
}

close_popup = function (id) {
  var close_button = $('#' + id + "_close_button");
  if (close_button) {
    close_button.click();
  }
}

preSelectCoupons = function (elementWithCouponCodes, course_id) {
  elementWithCouponCodes = $(elementWithCouponCodes);
  var codes = elementWithCouponCodes.innerHTML.split(' ');
  if (codes.size() > 0) {
    $('#coupon_available_list li').each(function(li){
      li = $('#coupon_selected_list li')[li]
      var input = li.querySelectorAll('input')[0];
      var code = li.querySelectorAll('label')[0].innerHTML.strip();
      ////console.log(input.value);
      if(codes.indexOf(code) != -1) {
        input.click();
      }
    });
  }
}

showPrintPreview = function () {
  setTimeout('jQuery("#order_preview").show();', 50);
  jQuery('#site_container').addClass('pointer_none');
}

hide_unhide_pricing_plan_fields = function () {
  var paid_column_text = '';

  if(jQuery('#company_pricing_plan_1').is(':checked')) {
    jQuery('#paid_subscription_fields').hide();
    jQuery("#pricing_type_container").show();

    jQuery('.price_column').show();
    jQuery('.member_column').show();
    jQuery('.price_schedule_column').show();
    jQuery('.discount_column').show();

    jQuery('.only_for_pay_per_course').show();
    jQuery('.only_for_subscription').hide();

    jQuery('.paid_column').addClass("spans_1").removeClass('spans_2_half');

    paid_column_text = 'Paid';

  } else if (jQuery('#company_pricing_plan_2').is(':checked')){
    jQuery('#paid_subscription_fields').show();
    jQuery("#pricing_type_container").hide();

    jQuery('.price_column').hide();
    jQuery('.member_column').hide();
    jQuery('.price_schedule_column').hide();
    jQuery('.discount_column').hide();

    jQuery('.only_for_pay_per_course').hide();
    jQuery('.only_for_subscription').show();

    jQuery('.paid_column').removeClass("spans_1").addClass('spans_2_half');

    paid_column_text = 'Subscriber only';
  }
  if($('#paid_column_text') != undefined){
    jQuery('#paid_column_text').html(paid_column_text);
  }
}

show_hide_discount_option = function (product_id){
  var paid_product = jQuery(".price_schedule_"+ product_id +"_free_false").prop('checked') || jQuery(".member_price_schedule_"+ product_id +"_free_false").prop('checked');
  if(paid_product) {
    jQuery("#coupons_section_course_" + product_id).show();
    jQuery("#coupons_section_certification_" + product_id).show();
    jQuery("#coupons_section_live_event_" + product_id).show();
    jQuery("#coupons_section_in_person_event_" + product_id).show();
  } else {
    jQuery("#coupons_section_course_" + product_id).hide();
    jQuery("#coupons_section_certification_" + product_id).hide();
    jQuery("#coupons_section_live_event_" + product_id).hide();
    jQuery("#coupons_section_in_person_event_" + product_id).hide();
  }
}

flash = function (message, type, duration) {
  jQuery("#flash_container").addClass('z_index_1000');
  if (jQuery('#flash_notice_content').css('display') != 'none') {
    setTimeout(function() {
      flash(message, type, duration);
    }, 20);
  }else{
    if (duration == null) {
    duration = 5;
    }
    if (type == null) {
      type = 'flash_notice';
    }

    jQuery('#flash_notice_content').css('display', 'block');
    jQuery('#flash_notice_text').html(message)

    setTimeout(function() {
      jQuery('#flash_notice_content').css('display', 'none');
    }, duration * 1000);
  }
}

showOrHideTourTab = function (intro_content){
  tour_present = false;
  intro_content.forEach(function(element){
    if(document.querySelector(element[0]) != null && document.querySelector(element[0]) != undefined && jQuery(element[0]).is(':visible')){
      tour_present = true;
    }
  });
  tour_present ? jQuery(".takeATour").show() : jQuery(".takeATour").hide()
}

startIntro = function (intro_content, next, back){
  var intro = introJs();
  var intro_steps = [];
  
  intro_content.forEach(function(element){
    if(document.querySelector(element[0]) != null && document.querySelector(element[0]) != undefined && jQuery(element[0]).is(':visible')){
      intro_steps.push({ 
        element: document.querySelector(element[0]),
        intro: element[1],
        position: element[2],
        tooltipClass: "curved_corner " + element[3],
        numberingPosition: element[4]
      });
    }
  });
    
  intro.setOptions({
    steps: intro_steps,
    displaySkipButton:  true,
    nextLabel: next + ' <span style="position: relative;top: -2px;">&rarr;</span>',
    prevLabel: '<span style="position: relative;top: -2px;">&larr;</span> ' + back
  });

  intro.start();
}