var placeholder;
handleTextChange = function (event) {
  var element = event.target;
  if (element.id != 'newTagsInput')
    return;
  var parent = element.parentNode;
  var tmpElement = jQuery(parent).find('div#tmpTagsInput');
  tmpElement.innerHTML = element.value;
  var width = tmpElement.clientWidth;
  var newTagsInputWrapper = parent

  if(jQuery('taginput_fixed_width').value == 'false') {
    width = (width > 23 ? width : 23);
    newTagsInputWrapper.setStyle({'width': width + 'px'});
  }
}

handleKeyPress = function (event){
  var element = event.target;

  if (element.id != 'newTagsInput')
    return;

  var charCode = event.charCode;
  var keyCode = event.keyCode;
  if (charCode == 0) {
    return processSpecialKey(event);
  }
  else if (charCode == 32) {
    return processSpace(event);
  }
}

processSpecialKey = function (event) {
  var element = event.target;
  var keyCode = event.keyCode;

  switch (keyCode) {
    case 37: //Left
      break;
    case 39: //Right
      break;
    case 8: //Back
      if(element.value == '') {
        var item = jQuery(element).closest('li');
        var previousSibling = item.previous();
        if(previousSibling) {
          removeTagItem(previousSibling);
        }
      }
      break;
    case 46: //Del
      break;
    case 13:
      processSpace(event);
  }
}

processSpace = function (event, el, hidden_value) {
  var element, value;
  if (event) {
    element = event.target;
  } else if (el) {
    element = el;
  }
  if(value == '') {
    // nothing
  } else if(!tagExists(element) && hidden_value) {
    insertTagItem(element, hidden_value);
    jQuery(element).val('');
  } else {
    element.value = '';
  }
  if (event) {
    event.stopPropagation();
  }
}

insertTagItem = function (textBoxElement, hidden_value) {
  var textBoxValue = textBoxElement.value.split(',')[0];
  var tagListItem = textBoxElement.parentNode;

  var newListItem = createNewTagListItem(textBoxValue, hidden_value);
  newListItem.insertBefore(tagListItem);
  var newTagsEditor = tagListItem.closest('div');

  var hiddenInput = jQuery(newTagsEditor).parent().find('input.tags');
  if(hiddenInput.value == undefined) {
    hiddenInput.val(textBoxValue);
  } else {
    hiddenInput.val(value + ' ' + textBoxValue);
  }
  if(jQuery('.newShareItem').length == 1) {
    placeholder = jQuery('#newTagsInput').attr('placeholder');
  }
  jQuery('#newTagsInput').removeAttr('placeholder');
}

createNewTagListItem = function(tagText, hidden_value) {
  var id = 'tag-' + tagText.replace(' ', '_');
  var li = jQuery('<li>', {'id': id, 'class': 'newTagItem newTagListItem newShareItem'});
  var a = jQuery('<a>');
  var em = jQuery('<em>', {'class': 'tagName', 'title': tagText});
  var value = hidden_value || tagText;
  em[0].innerHTML = tagText;
  var span = jQuery('<span>', {'class': 'rm', 'title': 'Remove this tag', 'onclick': "removeTagItem(jQuery(this).closest('li.newTagItem'))"});
  var hidden = jQuery('<input>', {'id': 'tag[]', 'name': 'tag[]', 'type': 'hidden', 'value': value.trim(), 'class': 'tag_hidden_field'});
  a.append(em);
  a.append(span);
  a.append(hidden);
  li.append(a);
  return li;
}

removeTagItem = function(tagItemToRemove) {
  //var tagItemToRemove = spanElement.up('li.newTagItem');
  var newTagsEditor = jQuery(tagItemToRemove).parent().closest('div');
  var hiddenInput = newTagsEditor.parent().find('input.tags')[0];
  var hiddenInputValue = hiddenInput.value;
  var valueToRemove = tagItemToRemove[0].id.replace('tag-', '');
  valueToRemove = valueToRemove.replace('_', ' ');
  tagItemToRemove.remove();
  hiddenInput.value = replaceString(hiddenInputValue, valueToRemove);
  if(jQuery('.newShareItem').length == 0) {
    jQuery('#newTagsInput').attr('placeholder', placeholder);
  }
}

replaceString = function(originalValue, valueToRemove) {
  if (valueToRemove.endsWith(',') || valueToRemove.endsWith('.')) {
    valueToRemove = valueToRemove.slice(0,-1);
  }
  var re = new RegExp("\\b" + valueToRemove + "\\b([,\\.]){0,1}", "g");
  var newValue = originalValue.replace(re, '');
  return newValue.trim(" ");
}

tagExists = function(inputElement) {
  var tagValue = inputElement.value;
  var newTagsEditor = jQuery(inputElement).closest('div');
  var hiddenInput = jQuery(newTagsEditor).parent().find('input.tags');
  var hiddenInputValue = hiddenInput.value ? hiddenInput.value : '';
  var tags = hiddenInputValue.split(' ');
  return searchArray(tags, tagValue);
}

searchArray = function(array, searchStr) {
  var returnArray = false;
  for (i=0; i<array.length; i++) {
    if (typeof(searchStr) == 'function') {
      if (searchStr.test(array[i])) {
        if (!returnArray) { returnArray = [] }
        returnArray.push(i);
      }
    } else {
      if (array[i]===searchStr) {
        if (!returnArray) { returnArray = [] }
        returnArray.push(i);
      }
    }
  }
  return returnArray;
}

AttachTagEditorEvents = function() {
  jQuery('.newTagInput').each(function(e) {
    jQuery(this).bind('keypress', handleKeyPress);
    jQuery(this).bind('change', handleTextChange);
    jQuery(this).bind('keyup', handleTextChange);
    jQuery(this).bind('blur', processSpace);
  });

  jQuery('span.rm').each(function(e) {
    jQuery(e).bind('click', function(){
      removeTagItem(this.closest('li.newTagItem'))
    });
  });

  AttachFocusOnClickEvent()
}

AttachFocusOnClickEvent = function() {
  newTags = jQuery("List").each(function() {
    var newTagsInput = jQuery(this).find('input#newTagsInput');

    jQuery(this).bind('click', function() {
      newTagsInput.focus();
    });
  });
}

updateElementForMessages = function(element) {
  var value = '';
  value = element.text.split(',')[1];
  
  processSpace(null, jQuery("#newTagsInput")[0], value);
}