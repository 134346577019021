// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("packs/common_helper.js")
// require("trunk8.js")
require("packs/view_helper.js")
require("packs/property_slider.js")
require("video.js")
require("packs/jquery.datetimepicker.full.js")
require("packs/tinymce.js")
// require("jquery")
require("jquery-ui")
require("packs/jquery.multiselect.js")
require("packs/tag.js")
require("packs/manage_site_events.js")
require("chartkick")
require("chart.js")
require("packs/search_helper.js")
require("packs/jquery.print.js")
require("packs/intro.js")
import 'jquery'


var ProgressBar = require('progressbar.js');
var prototype = require('prototype');
var jwt = require('jsonwebtoken');
var { ellipsis } = require('ellipsed');
import pdfjs from 'pdfjs-dist/webpack';
import Sortable from 'sortablejs';


// require("packs/graph.js")
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
window.jQuery = $;
window.$ = $;
window.videojs = videojs;
window.pdfjs = pdfjs;
window.ProgressBar = ProgressBar;
window.Sortable = Sortable;
window.prototype = prototype;
window.jwt = jwt;
window.ellipsis = ellipsis;

function submit_form(selector){
  jQuery(selector).submit();
  return false;
}

document.addEventListener('turbolinks:load', function() {
	const remoteLinks = Array.from(document.querySelectorAll("a[data-remote='true']"))
	remoteLinks.forEach(function(element) {
	  element.dataset.url = element.href
	  element.href = "javascript:void(0);"
	})

	Rails.href = function(element) {
	  return element.dataset.url || element.href
	}
})