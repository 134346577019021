update_selected_count = function () {
  var count = $('div#selected_content_items input[type=hidden]').length;
  if (count == 1) {
    $('#selected_content_item_count').html(count + " item selected");
  }
  else {
    $('#selected_content_item_count').html(count + " items selected");
  }
}

check_selected_items = function() {
  $("div#selected_content_items input[type=hidden]").each(function(selectedElement){
    check_content_checkbox(selectedElement.value.toString());
  });
}

get_tincan_content_iframe = function () {
  if(jQuery('#content_picker_panel').is(':visible')){
    return jQuery('#content_picker_panel').find('iframe#tincan_content_frame')[0];
  }
  else {
    return jQuery('#tincan_content_frame')[0];
  }
}

update_content_selection = function (content_id, was_selected) {
  var selected_items_container = $('#selected_content_items');
  if(was_selected) {
    if($("div#selected_content_items input[value=" + content_id.toString() + "]").length == 0) {
      var element = document.createElement("INPUT");
      element.setAttribute("name", "selected_contents[]");
      element.setAttribute("value", content_id.toString());
      element.setAttribute("type", "hidden");
      selected_items_container.append(element);
    }
  } else {
    $("div#selected_content_items input[value=" + content_id.toString() + "]").remove();
  }
}

before_insert_document_checks = function() {
  var question_pool_id = $('course_question_pool_id') && $('course_question_pool_id').value;
  $('question_pool_id').value = question_pool_id === null ? '' : question_pool_id;
  if($('from_certification') && $('from_certification') == 'true')   {
    $('opened_from').value = 'certification';
  } else {
    $('opened_from').value = '';
  }
}

insert_selected_items_in_form = function () {
  $("div#selected_content_items input[type=hidden]").each(function(selectedElement) {
    // var element = new Element('input', {
    //   'class': 'selected_items',
    //   'value': $(this).text().toString(),
    //   'type': 'hidden',
    //   'name': 'content_id[]'}
    // );
    var element = jQuery('<input>', {
      'class': 'selected_items',
      'value': $(this).val().toString(),
      'type': 'hidden',
      'name': 'content_id[]'
    })
    $('#form_insert_content_in_course').append(element);
  });
}


insert_selected_products_in_form = function() {
    $("div#selected_content_items input[type=hidden]").each(function(selectedElement) {
        var element = new Element('input', {
                'class': 'selected_items',
                'value': $(this).val().toString(),
                'type': 'hidden',
                'name': 'product_id[]'}
        );
        $('form_insert_content_in_course').appendChild(element);
    });
}


check_content_checkbox = function(value, contentId) {
  var content_id = contentId ? contentId : 'content_id'
  var check_boxes = $("input:visible[id*="+content_id+"_]");
  check_boxes.each(function(index,checkBox) {
    if ($(this).val() == value )
    {
      // $(this).click();
      $(this).trigger( "click" );
      // $(this).attr('checked',true);
      // throw $break;
    }
  });
}

delete_content = function (content_id) {
  var element_to_be_deleted = $('#'+content_id);
  var is_selected = element_to_be_deleted.hasClass('selected');
  if (is_selected) {
    next_siblings = element_to_be_deleted.next();
    prev_sibling = element_to_be_deleted.prev();
    if (next_siblings.length > 1) {
      next_siblings[0].addClass('selected');
      next_siblings[0].select('.content').first().onclick();
    }
    else if (prev_sibling) {
      prev_sibling.addClass('selected');
      prev_sibling.find('.content').first().click();
    }
  }
  jQuery(document).trigger('content_item_percentage.item_removed', [jQuery('#' + content_id)]);
  element_to_be_deleted.remove();
    setQuizPropertiesVisibility();
  if (is_selected) {
      $('#viewer').innerHTML='';
  }
  if ($('.sortable').length == 0) {
    jQuery('#add_content_message').show();
  }
}

submit_question_and_setup_new = function(question_type) {
  var question_form = $('#questionnaire_question_form');
  if(question_form.length > 0) {
    var question_type_field = $('#questionnaire_question_form #question_type').first();
    var save_questionnaire_field = $('#questionnaire_question_form #save_questionnaire').first();
    var page_number = $('#questionnaire_question_form #page_number').first();
    var current_page_number = $('#questionnaire_question_form #current_page_number').first();
    question_type_field.val(question_type);
    save_questionnaire_field.val('');
    page_number.val('');
    current_page_number.val('');
    jQuery('#submit_question_form').click();
  } else {
    if(question_type == 'radiobutton') {
      $('#single_answer_link')[0].click();
    } else if(question_type == 'checkbox') {
      $('#multiple_answer_link')[0].click();
    } else if(question_type == 'yesno') {
      $('#yesno_answer_link')[0].click();
    } else if(question_type == 'fill_blanks') {
      $('#fill_blanks_answer_link')[0].click();
    } else if(question_type == 'hotspot') {
      $('#hotspot_answer_link')[0].click();
    } else if(question_type == 'textarea'){
      $('#multiline_answer_link')[0].click();
    }
  }
}

stopEventPropogation = function (event) {
  // if (Prototype.Browser.IE) {
  //   event.cancelBubble = true ;
  // } else {
  //   event.stopPropagation();
  // }
  event.stopPropagation();
}

onContentPickerCancel = function () {
  if(jQuery('#new_in_person_event_form, #edit_in_person_event_form').length > 0) {
      lb_sizer('in_person_event');
  }
  newCoursePanel();
  var content = $('#new_course_panel .thumbnail.selected .content').first();
  if(content) {
    content.click();
  }
  if(jQuery('#certificate_title').length > 0) {
        // do nothing
  } else {
      show_add_content_link();
  }

  $('#content_picker_panel').hide();
  $('#content_picker_panel')[0].innerHTML = '';
  updatePopupTitle();
}

newCoursePanel = function () {
  $('#new_course_panel').show();
}

set_current_date_time_if_empty = function (date_element, time_element) {
  var ed, et;
  ed = $('#'+date_element);
  et = $('#'+time_element);
  if(ed.val() == '') {
    ed.val(Date.now().toString("MMMM d, yyyy"))
  }
  if(et.val() == '') {
    et.val(Date.now().toString("h:mm tt"))
  }
}

hideAllBalloonPopups = function (except) {
  var ignore = [];
  except = except ? $(except) : except;

  if(except) {
    ignore.push(except.attr('id'))
  } else {
      var blog_post_popups = jQuery('div.balloon_popup_container[id^=edit_post_], div.balloon_popup_container[id=create_post]');
      blog_post_popups.each(function(i, e) {
        ignore.push(e.id);
      });
  }

  var lightbox_popups = jQuery('div.balloon_popup_container.has_lightbox');
    lightbox_popups.each(function(i, e) {
        ignore.push(e.id);
    });

  $('.balloon_popup_container').each(function(e){
    // If popup) to be hidden should not be ignored and its is not already hidden then hide it.
    if((ignore.indexOf(this.id) == -1) && $(this).css('display') != 'none') {
      var close_button = $(this.id + "_close_button");
      if (close_button) {
        $(e.id + "_close_button").click();
      }
    }
  });
}

hideAllRoundedDropdownMenus = function (except, container) {
  container = (container == null) ? '.dropdown_menu_container' : container
  if (except) {
    except = $("#"+except);
  }
  $(container).each(function(e){
    if((!except || this.id != except.attr('id')) && $(this).css('display') != 'none') {
      $(this).hide();
    }
  });
}

select_applied_items = function (container, item_type, checkbox_class) {
  var checkbox;
  //Uncheck all checkboxes
  $('.' + checkbox_class).each(function(checkbox){
    if(checkbox.checked === true) {
      checkbox.click();
    }
  });

  $('#' + container + ' input[type=hidden]').each(function (input) {
    value = input.value;
    if(jQuery.isArray(item_type)) {
        jQuery(item_type).each(function(index, item) {
            checkbox = $('#selected_' + item + '_' + value.toString());
            if(checkbox) {
                checkbox.click();
            }
        });

    } else {
        checkbox = $('#selected_' + item_type + '_' + value.toString());
        if(checkbox) {
            checkbox.click();
        }
    }

  });
}

resetPromptText = function (field_id, value) {
  if (($('#'+field_id).val() == value)) {
    $('#'+field_id).val('');
    $('#'+field_id).css('color','black');
  }
}

setPromptText = function (field_id, value) {
  if (($('#'+field_id).val() == "")) {
    $('#'+field_id).val(value);
    $('#'+field_id).css('color','#CCCCCC');
  }
}

categoryTagSearch = function (textBox, listToSearchFrom, createLinkId, search_reset_id, url, search_val) {
  textBox = $('#'+textBox);
  var equal = searchit(textBox, listToSearchFrom);
  var link = jQuery('#' + createLinkId);
  link.attr("href",url + '&category[name]=' + encodeURIComponent($('#'+search_val).val()));
  link.html(textBox.val() + " (create new)");
  if(equal || textBox.val().trim().length == 0) {
    $('#'+search_reset_id).hide()
    link.hide();
  } else {
    link.show();
    $('#'+search_reset_id).show()
  }
}

edit_request_url = function(url,value,edit_link_id) {
  var link = jQuery('#' + edit_link_id);
  link.attr("href",url + '&category[name]=' + encodeURIComponent(value));
}

searchit = function (field_name, list_container_id, tag_filter, css_to_select){
    var equal = false;
    var matched = false;
    var keyword = field_name.val();
    if(tag_filter == true) {
      $('#'+list_container_id).show();
      var selected_list = $('#'+list_container_id).select('div.auto_complete_selected_list_container')[0];
      var available_list = $('#'+list_container_id).select('div.auto_complete_available_list_container')[0];
      selected_list.show();
      available_list.show();
    }
    var css_to_select = css_to_select || "li";
    $('#'+list_container_id+' '+css_to_select).each( function(ele){
      var label = $('#'+this.id+' '+'label');
      if (!($(this).hasClass('selection_none'))) {
        if (label == undefined || label == null) {
          $(this).attr('display','none');
        } else {
          // var element_text = label.text();
          // element_text = element_text.replace(/<(\/)?b>/gi,'');
          // label.text(element_text)
          var label_text = label.text();
          matches = label_text.match(new RegExp(keyword, "gi"));
          if (matches == null) {
            $(this).hide();
            // $(this).attr('display','none');
          } else {
            matched = true;
            if (label_text.toLowerCase() == keyword.toLowerCase()) {
              equal = true;
            };
            $(this).show();
            matches.forEach(function(str){
              $('#'+this.id+' '+'label').text(label_text.replace(str, str.bold()));
            });
            }
          }
      }
    });

    if(tag_filter == true) {
      if(matched == false ) {
        $(list_container_id).hide();
      } else {
        if (keyword != '') {
          var selected_list = $(list_container_id).select('div.auto_complete_selected_list_container')[0];
          var visible_items = selected_list.select('li').findAll(function(el) {return el.visible();});
          if(visible_items.length < 1) {
            selected_list.hide();
          }
          var available_list = $(list_container_id).select('div.auto_complete_available_list_container')[0];
          var visible_items = available_list.select('li').findAll(function(el) {return el.visible();});
          if(visible_items.length < 1) {
            available_list.hide();
          }
        }
      }
    }

    return equal;
};

removetext = function (search_reset_id, textbox_id) {
  $('#'+textbox_id).val("");
  $('#'+search_reset_id).hide();
}

show_hide = function (show, hide) {
    $('#'+show).show();
    $('#'+hide).hide();
}

reset_search_text = function (textbox_id){
  jQuery("#" + textbox_id).val("");
  jQuery("#" + textbox_id).trigger('keyup');
}

apply_selected_categories = function (check_boxes, new_check_box_name_1, id_to_update, new_check_box_name_2, div_id, choosable_type_group) {
  $('#'+id_to_update).html("");
  var names = [];
  var i = 0;
  var choosable_type_group = choosable_type_group ? true : false
  check_boxes.each(function(i,checkbox) {
    if ($('#' + this.id).is(":checked")) {
      names[i] = $(this)[0].getAttribute('text');
      i++;
      var element = document.createElement("INPUT");
      element.setAttribute("name", new_check_box_name_1);
      element.setAttribute("value", $(this).val());
      element.setAttribute("type", "hidden");
      $('#'+id_to_update).append(element);
      if ( new_check_box_name_2 != null && new_check_box_name_2 != undefined ) {
        var element = document.createElement("INPUT");
        element.setAttribute("name", new_check_box_name_2);
        element.setAttribute("value", checkbox.getAttribute('item_type'));
        element.setAttribute("type", "hidden");
        $('#'+id_to_update).append(element);
      }
    }
  })

  if (names.length == 0 && !choosable_type_group) {
    var element = document.createElement("INPUT");
    element.setAttribute("name", new_check_box_name_1);
    element.setAttribute("type", "hidden");
    $('#'+id_to_update).append(element);
  }
  var only_certain_group = $('#' + div_id +'_display_in_catalog_2').first();
  if (choosable_type_group && names.length == 0 && only_certain_group && only_certain_group.checked)
  {
    var element = document.createElement("SPAN");
    element.setAttribute('style', 'color:red');
    $('#'+id_to_update).append(element);
    $('#'+id_to_update).text('Choose atleast one group');
    $('.' + div_id +'_product_groups').hide();
  }
  else {
    var element = document.createElement('SPAN');
    element.classList.add('selected_categories');
    element.innerHTML = (names.length == 0) ? 'None' : names.join(", ");
    $('#'+id_to_update).append(element);
  }
  return false;
}

toggleSelectionList = function (id, name, use_id_for_checkbox_list, sortable, append_none, second_word_sort, ignore_list_class, none_text) {
  var append_none = append_none == null ? true : append_none;
  var sort_using_second_word = second_word_sort ? second_word_sort : false;
  var checkbox = $('#selected_' + name + '_' + id);
  var checkbox_list = $('#list_' + id);
  if(use_id_for_checkbox_list) {
    checkbox = $('#selected_' + id);
    checkbox_list = $('#list_' + id);
  }
  if (checkbox.is(":checked")) {
    if (sortable == true) {
      var element = document.createElement('img');
      element.setAttribute('src','/assets/icons/drag.png');
      element.setAttribute('id','product_draggable_item');
      element.setAttribute('onmouseover','dragging()');
      element.setAttribute('onclick','drag()');
      element.classList.add("sort_handle","float_right");
      $('#list_' + id + ' '+'div')[0].append(element);
    }
    $('#' + name + '_selected_list').append(checkbox_list);
    var none_label =  $('#' + name + '_selected_list li.selection_none')[0];
    if(none_label!= null && none_label!= undefined) {
      none_label.remove();
    }
    if (sortable != true) {
      if(ignore_list_class != null && ignore_list_class != undefined){
        sortList(name + '_selected_list', sort_using_second_word, ignore_list_class);
      }else{
        sortList(name + '_selected_list', sort_using_second_word);
      }
    }
    if (append_none){
      var available_list = $('#' + name + '_available_list').children();
      var none_label =  $('#' + name + '_available_list li .selection_none li.selection_none')[0];
      if (available_list.length == 0 && (none_label == null || none_label == undefined)) {
        appendNone($('#' + name + '_available_list'));
      }
    }
  } else {
    if (sortable == true) {
      $('#list_' + id + ' ' + '.sort_handle')[0].remove();
    }
    $('#' + name + '_available_list').append(checkbox_list[0]);
    var available_list = $('#' + name + '_available_list').children();
    var none_label =  $('#' + name + '_available_list li .selection_none')[0];
    if(available_list.length != 0 && none_label!= null && none_label!= undefined) {
      none_label.remove();
    }
    sortList(name + '_available_list', sort_using_second_word);
    // display none in the selected list section if that section is empty
    if (append_none){
        var selected =  $('#' + name + '_selected_list [type="checkbox"]')[0];
        var enrolled =  ($('#' + name + '_enrolled_list') != null && $('#' + name + '_enrolled_list') != undefined) ? $('#' + name + '_enrolled_list [type="checkbox"]')[0] : null;
        var none_label =  $('#' + name + '_selected_list li .selection_none')[0];
        if ((enrolled== null || enrolled== undefined) && (selected== null || selected== undefined) && (none_label == null || none_label == undefined)) {
          appendNone($('#'+name + '_selected_list'), none_text);
        }
    }
  }
  if (sortable == true) {
    Sortable.create(name + '_selected_list',{tag:'li', handle: 'sort_handle'});
  }
}


sortList = function (id, second_word_sort, ignore_list_class){
    var container = jQuery('#' + id);
    if(ignore_list_class != null && ignore_list_class != undefined){
      var selected_list = container.find("li").not("."+ignore_list_class);
    }else{
      var selected_list = container.find("li");
    }
    var sort_using_second_word = second_word_sort ? second_word_sort : false
    selected_list.sort(function(a, b){
      if(sort_using_second_word == true){
        return jQuery(a).find("label").text().split(" ")[1].toUpperCase().localeCompare(jQuery(b).find("label").text().split(" ")[1].toUpperCase());
      }else {
        return jQuery(a).find("label").text().toUpperCase().localeCompare(jQuery(b).find("label").text().toUpperCase());
      }
    });
    jQuery.each(selected_list, function(e, itm){container.append(itm)});
}

appendNone = function (list_container, text) {
  text = text != undefined && text != null ? text : "None"
  var element = document.createElement("LI")
  element.classList.add('selection_none')
  element.innerHTML = text;
  list_container.append(element);
}

update_price_fields = function (container_id) {
  paid_product = jQuery("#" + container_id + "_scrollbar_content .paid").find(":input").prop('checked');
  if (paid_product) {
    price = jQuery("#" + container_id + "_scrollbar_content").find(":input#" + container_id + "__price").val();
    jQuery("#current_" + container_id).find(":input#" + container_id + "__price").val(price);
    jQuery("#current_" + container_id).find(":input#" + container_id + "__free_false").click();
  } else {
    jQuery("#current_" + container_id).find(":input#" + container_id + "__free_true").click();
  }
}

hide_unhide_passing_percent_fields = function (show) {
  if(show) {
      jQuery('.passing_percent_fields').show();
  } else {
      jQuery('.passing_percent_fields').hide();
  }
}

hide_unhide_product_suggestion_popup_container = function (show) {
  if(show) {
      jQuery('.product_suggestion_popup_container').show();
  } else {
      jQuery('.product_suggestion_popup_container').hide();
  }
}

hide_unhide_question_specific_fields = function (show) {
    if(show) {
        jQuery('.question_specific_fields').show();
    } else {
        jQuery('.question_specific_fields').hide();
    }
}

show_add_content_link = function () {
  element = document.createElement("a");
  element.setAttribute('onclick','$("#select_content_popup").toggle(); return false;');
  element.classList.add('orange','button');
  element.innerHTML = '+ Add Content';
  $('#popupTitleLink').html('');
  $('#popupTitleLink').append(element);
}

selectSuggestedItemsOnSubmit = function (){
  if(jQuery("#product_suggestion_popup_container").is(':visible')){
    jQuery(".suggestion_popup_container").hide();
    selectSuggestedItems();
  }
}

submit_form = function (selector){
  // jQuery(selector).submit();
  // jQuery(selector).trigger('submit.rails');
  var elem = document.getElementById(selector) // or $('#myform')[0] with jQuery
  Rails.fire(elem, 'submit');
  return false;
}

// lb_sizer = function (class_name) {
//   $('#lightbox').attr('class', "'lightbox_' + class_name");
//   scrollOffset = document.viewport.getScrollOffsets();
//   window.scrollTo(0,0);
//   lb_helper('show', 'lightbox');
// }

lb_sizer = function (class_name) {
  $('#lightbox').attr('class', 'lightbox_' + class_name);
  scrollOffset = document.viewport.getScrollOffsets();
  window.scrollTo(0,0);
  lb_helper('show', 'lightbox');
}

lb_helper = function (process, id) {
  if (process == 'show') {
    // Hiding all Flash content because it overlaps the lightbox in IE.
    $("*.flash").each(function(element) {
      element.style.display = "none";
    });
    add_remove_class([], ['lightbox_container', id]);
    add_remove_class([], ['lightbox_overlay', id]);

    var height =  getPageSize()[1],
        footerHeight = 0;

    if(isLoginLightBoxLayout()) {
        footerHeight = 304;  //jQuery('#site_footer').height();
        height -= footerHeight;
    }
    $('lightbox_container').style.height = height + 'px';
    $('lightbox_overlay').style.height = height + 'px';
  } else {
    // Showing all Flash content.
    $$("*.flash").each(function(element) {
      element.style.display = "block";
    });

    add_remove_class(['lightbox_container', id], []);
    add_remove_class(['lightbox_overlay', id], []);
    $('lightbox_container').style.height = 'auto';
    $('lightbox_overlay').style.height = 'auto';
    $(id).style.top = '';
    $(id).innerHTML = '';
    $('lightbox_overlay').removeClassName('lightbox_overlay_5');
    jQuery("#lightbox_container").trigger("closed", []);
  }
}

add_remove_class = function (add_me, remove_me, toggled) {
  if (toggled == null) {
    toggled = 'hidden';
  }

  // hide everything before showing anything
  var cur = null;
  if (add_me instanceof Array) {
    while(cur = add_me.pop()) {
      if ($(cur)) {
        $(cur).addClassName(toggled);
      }
    }
  } else {
    if ($(add_me)) {
      $(add_me).addClassName(toggled);
    }
  }
  if (remove_me instanceof Array) {
    while(cur = remove_me.pop()) {
      if ($(cur)) {
        $(cur).removeClassName(toggled);
      }
    }
  } else {
    if ($(remove_me)) {
      $(remove_me).removeClassName(toggled);
    }
  }
}

updatePopupTitle = function (title_text) {
  if(title_text == null) {
    var span = $('#new_course_panel span.title_text').first();
    if (span) {
      title_text = span.val();
    }
  }
  var title_element = $('.popup_header .title').first();
  if(title_element) {
    title_element.val(title_text);
    title_element.text(title_text);
  }
}

hide_lightbox = function () {
  // videojs_dispose();
  jQuery("#lightbox_container").trigger("before_close", []);
  // lb_helper('hide', 'lightbox');
  if(scrollOffset) {
    window.scrollTo(scrollOffset.left, scrollOffset.top);
  }
}

hide_unhide_catalog_date_fields = function (prefix) {
  if($('#'+prefix + '_display_in_catalog_0').is(":checked")) {
    $('#catalog_date_fields').hide();
    jQuery('.group_fields').hide();
  } else if  ($('#'+prefix + '_display_in_catalog_1').is(":checked")){
    if ($('#catalog_date_fields').children().length == 0) {
      $('#catalog_date_fields').append($('#date_fields'));
    }
    $('#catalog_date_fields').show();
    jQuery('.group_fields').hide();
  } else {
    if ($('#group_date_fields').children().length == 0) {
      $('#group_date_fields').append($('#date_fields'));
    }
    $('#catalog_date_fields').hide();
    jQuery('.group_fields').show();
  }
}

enable_disable_auto_enroll_field = function (prefix, free) {
    var auto_enroll_true = $('#' + prefix + '_auto_enroll_true'),
        auto_enroll_false = $('#' + prefix + '_auto_enroll_false');

    if(free == null || free == undefined){
      var free_button = $('#price_schedule__free_true'),
          member_free_button = $('#member_price_schedule__free_true'),
          paid_button = $('#price_schedule__free_false'),
          member_paid_button = $('#member_price_schedule__free_false');
    }

    var item_free = free_button != null && free_button != undefined && free_button.is(":checked");
    var memebr_item_free = (member_free_button != null && member_free_button != undefined) ? member_free_button.is(":checked") : true;
    var item_paid = paid_button != null && paid_button != undefined && paid_button.is(":checked");
    var member_item_paid = (member_paid_button != null && member_paid_button != undefined) ? member_paid_button.is(":checked") : false;

    if (free == true || (item_free && memebr_item_free)) {
        auto_enroll_true.disabled = false;
    } else if(free == false || (item_paid || member_item_paid)) {
        auto_enroll_false.checked = true;
        auto_enroll_true.disabled = true;
    }
}

toggle_publishing_options_radio_label_class = function (prefix) {
  var el = $('.publishing_options.loud').first();
  if(el) {
      el.removeClass('loud');
  }
  $('#'+prefix + '_label').addClass('loud');
}

align_item_group_label = function (item_name, checked){
  jQuery("#" + item_name + "_display_in_catalog_2").css("margin-right", (checked ? "0px" : "10px"));
  if(checked){
    jQuery("#group_label").addClass("float_right");
  }else{
    jQuery("#group_label").removeClass("float_right");
  }
  jQuery("#group_label").css("width", (checked ? "90%" : "85%"));
}

send_selected_ids = function (input_name){
  alert("ok");
  var selected_ids = [];
  var i = 0;
  jQuery('input[name="'+input_name+'"]').each(function() { selected_ids[i] = jQuery(this).val(); i++ });

  return selected_ids;
}

toggleWaitBox = function (show, lightbox) {
  var waitbox = $('#waitbox');
  if (waitbox == undefined){
    return;
  }
  if (show) {
    document.body.style.cursor = 'wait';
    if(lightbox == true || jQuery('#lightbox').is(":visible")){
      waitbox.attr('position','absolute');
      var lb_pos = jQuery('#lightbox').offset();
      waitbox.removeClass('hidden');
      waitbox.attr('top', lb_pos.top + $('#lightbox').height()/2 - waitbox.height()/2 + 'px');
      waitbox.attr('left', lb_pos.left + $('#lightbox').width()/2 - waitbox.width()/2 + 'px');
      waitbox.addClass('hidden');
    } else {
      waitbox.attr('position','fixed');
      waitbox.attr('top','40%');
      waitbox.attr('left','45%');
    }
    waitbox.removeClass('hidden');
  } else {
    waitbox.addClass('hidden');
    document.body.style.cursor = 'default';
  }
}

toggleGoToTop = function ()
{
  //Check to see if the window is top if not then display button
  if (jQuery(this).scrollTop() > 100) {
    jQuery('.scrollToTop').fadeIn();
  } else {
    jQuery('.scrollToTop').fadeOut();
  }
}

assign_selected_users = function (check_boxes, new_check_box_name, id_to_update) {
    $('#'+id_to_update)[0].innerHTML = "";
    var names = [];
    var i = 0;
    var display_none = (display_none == null || display_none == undefined) ? true : display_none
    check_boxes.each(function(i,checkbox) {
      if ( $('#' + this.id).is(":checked") ) {
        names[i] = checkbox.getAttribute('text');
        i++;
        element = document.createElement("input");
        element.setAttribute('type', 'hidden');
        element.setAttribute('name', new_check_box_name);
        element.setAttribute('value', checkbox.value)
        $('#'+id_to_update).append(element);
      }
    })

    if (names.length == 0) {
      element = document.createElement("input");
      element.setAttribute('type', 'hidden');
      element.setAttribute('name', new_check_box_name);
      $('#'+id_to_update).append(element);
    }
    if(names.length != 0) {
      var update_string = (names.length > 5) ? names.sort(sort_by_second_word).slice(0,5).join(", ") +", and "+(names.length-5)+" more." : names.sort(sort_by_second_word).join(", ")
      element = document.createElement("span");
      element.setAttribute('class', 'selected_categories');
      element.innerHTML = update_string;
      $('#'+id_to_update).append(element);
    }
    return false;
}


sort_by_second_word = function (a, b){
  return (a.split(" ")[1] > b.split(" ")[1]) ? 1 : -1;
}

enableButton = function (buttonSelector) {
  var button = $(buttonSelector).first();
  if (button) {
    button.disabled = false;
    button.removeClass('disable');
    button.addClass('orange');
  }
}

setQuizPropertiesVisibility = function () {
    if(jQuery('div[survey=true]').length > 0 || jQuery('div[data-is_quiz=data-is_quiz]').length > 0) {
        hide_unhide_question_specific_fields(true);
    } else {
        hide_unhide_question_specific_fields(false);
    }

    if(jQuery('div[data-is_quiz=data-is_quiz]').length > 0) {
        jQuery('.passing_percent_fields').show();
    } else {
        jQuery('.passing_percent_fields').hide();
        jQuery('#product_suggestion_popup_container').hide();
    }
}

select_element = function (element, selection_criteria, select_style) {
  // element = $(element);
  if(!select_style) {
    select_style = 'selected';
  }
  $(selection_criteria).removeClass(select_style)
  // $$(selection_criteria).invoke('removeClassName', select_style);
  element.addClass(select_style);
}

switchtab = function(evt, tabname, classname) {
  var i, tabcontent, tablinks;
  tabcontent = document.getElementsByClassName(classname);
  for (i = 0; i < tabcontent.length; i++) {
    tabcontent[i].style.display = "none";
  }
  tablinks = document.getElementsByClassName("tablinks");
  for (i = 0; i < tablinks.length; i++) {
    tablinks[i].className = tablinks[i].className.replace(" active", "");
  }
  document.getElementById(tabname).style.display = "block";
  evt.currentTarget.className += " active";
}

ChangeSubmitUrl = function(tabname) {
  var i, tabcontent, tablinks;
  tabcontent = document.getElementsByClassName("video_content");
  for (i = 0; i < tabcontent.length; i++) {
    tabcontent[i].classList.remove("show");
  }
  tablinks = document.getElementById(tabname);
  tablinks.classList.add("show");
}

NewDocumentForm = function() {
  if (jQuery('.video_content.show').attr('id') == 'embed_video') {
    jQuery('#submit_new_embed').click();
  } else {
    jQuery('#submit_new_document').click();
  }
}

DateTimePicker = function(el_id,format,datepicker,timepicker) {
  $('#'+el_id).datetimepicker({ format: format, minDate: 0,datepicker: datepicker, timepicker:timepicker, autoclose: true, validateOnBlur: false, step: 30 });
}

change_settings = function (type) {

    form = $('#question_properties')[0];
    if (type == 'radiobutton') {
        set_answer_text(form.no_of_answers, radiobutton_answer_text);
        set_radio_correct_answer(form.correct_answer);
    }
    if(type == 'checkbox') {
        set_answer_text(form.no_of_answers, checkbox_answer_text);
        set_checkbox_correct_answers(form.correct_answer);
    }
    change_numbering(form.numbering);
    shuffle_answer_sel = form.shuffle_answer;
    $('#question_shuffle_answers').val(jQuery( "#"+shuffle_answer_sel.id+" option:selected" ).val());
    $('#multiple_choice_properties_popup').hide();

}

set_answer_text = function (no_of_answers_sel, button_type, answer_field_prompt) {
    var no_of_answers = jQuery( "#"+no_of_answers_sel.id+" option:selected" ).val();
    var displayed_ans_count = $('.answers').length;
    if (no_of_answers === displayed_ans_count) return;
    if (no_of_answers < displayed_ans_count) {
       for(i=displayed_ans_count-1; i > no_of_answers - 1; i--) $('.answers').children().eq(i).remove();
    } else {
       new_ans = no_of_answers - displayed_ans_count;
       for(i=0;i<new_ans;i++) {
         var ans_number = displayed_ans_count + i + 1;
         add_answer_text(button_type, answer_field_prompt, ans_number);
       }
    }
}

add_answer_text = function (button_type, answer_prompt, ans_number) {
    if(!answer_prompt) {
        answer_prompt = 'Please enter your answer here'
    }
    var div_to_insert = '<div class="row answers"><div style="width: 100%" class="column half_margin_top"><div class="row"><div class="column"> ' +
            '<div class="input_radio">'+
button_type() +
'</div></div><div class="column answering_seq">A)</div>' +
      '<div class="column spans_13 answer_text"><div style="z-index: ;" class="form_field"><div class="textarea">' +
      '<textarea rows="1" name="question[new_answer_attributes][][text]" placeholder="'+answer_prompt+'" id="question_new_answer_attributes_' + ans_number + '_text" class="answer autoExpand" required="required" onblur="question_text_on_blur(this,' + '\'' +  answer_prompt + '\'' + ')" onfocus="question_text_on_focus(this,'  + '\'' +  answer_prompt + '\'' + ')">' + '</textarea>' +
      '</div></div></div></div></div></div>'
    $('.answers').last().after(div_to_insert);
    // if(jQuery('#fill_in_blank_type').length <= 0) {
    //   $('.autoExpand').each(function(element) {
    //       jQuery('#' + element.id).autogrow({'expandTolerance': 1});
    //     }
    //   );
    // }
}

fill_blanks_answer_text  = function () {
    return '';
}

radiobutton_answer_text = function () {
  return '<input type="radio" value="true" name="question[new_answer_attributes][][is_correct]" id="question_new_answer_attributes__is_correct" onclick="select_unselect_questionnaire_radio(this)" class="ans_radio" >'
}

checkbox_answer_text  = function () {
  return '<input type="hidden" value="false" name="question[new_answer_attributes][][is_correct]">' +
    '<input type="checkbox" value="1" name="question[new_answer_attributes][][is_correct]" id="question_new_answer_attributes__is_correct" class="ans_radio" >';
}

select_unselect_questionnaire_radio = function (select_item){
    $('.ans_radio').prop('checked', false);
    select_item.checked = true;
}

set_radio_correct_answer = function (cor_ans_sel) {
    if(jQuery('#questionnaire_questionnaire_type').val() == 'Survey') {
        $('#multiple_choice_properties_popup').hide();
        return;
    }

    //first uncheck all checkbox
    $('.ans_radio').each(function(index,check_box){
        check_box.checked = false;
    });
    var correct_answer = cor_ans_sel[cor_ans_sel.selectedIndex].value;
    $('.ans_radio')[correct_answer - 1].checked = true;
}

change_numbering = function (numbering_select) {
    var numbering_values = [['A)', 'B)', 'C)', 'D)', 'E)', 'F)', 'G)', 'H)', 'I)', 'J)'],
                            ['1)', '2)', '3)', '4)', '5)', '6)', '7)', '8)', '9)', '10)']];
    // var numbering_seq = numbering_select[numbering_select.selectedIndex].value;
    var numbering_seq = jQuery( "#"+numbering_select.id+" option:selected" ).val();
    $('.answering_seq').each(function(index, node) {
      node.innerHTML = numbering_values[numbering_seq][index];
    });
    $('#question_numbering').val(numbering_seq);
}

submit_question_and_paginate = function (page, current_page) {
  var question_form = $('#questionnaire_question_form');
  if(question_form.length > 0) {
    var question_type_field = $('#questionnaire_question_form #question_type').first();
    var save_questionnaire_field = $('#questionnaire_question_form #save_questionnaire').first();
    var page_number = $('#questionnaire_question_form #page_number').first();
    var current_page_number = $('#questionnaire_question_form #current_page_number').first();
    question_type_field.val('');
    save_questionnaire_field.val('');
    page_number.val(page);
    current_page_number.val(current_page);
    jQuery('#submit_question_form').click();
  }
}

adjustQuestionSettingsPopup = function () {
  if(jQuery('#delete_question').is(':visible')){
    jQuery('#multiple_choice_properties_popup').css('right', '319px');
  }
  else {
    jQuery('#multiple_choice_properties_popup').css('right', '290px');
  }
}

populate_correct_answer_drop_down = function (no_of_answer){
   value = jQuery( "#"+no_of_answers.id+" option:selected" ).val();
   correct_answer = jQuery('#correct_answer')[0];
   correct_answer.options.length = 0; //clear the options first
   for(i=0;i<value;i++) {
     correct_answer.options[i] = new Option(i+1,i+1);
   }
   // jQuery("#correct_answer").multiselect('refresh');
}

set_checkbox_correct_answers = function (cor_ans_sel) {
  if(jQuery('#questionnaire_questionnaire_type').val() == 'Survey') {
      $('#multiple_choice_properties_popup').hide();
      return;
  }

  //first uncheck all checkbox
  $('.ans_radio').each(function(index, check_box){
      check_box.checked = false;
  });
  var correct_answer = jQuery("#correct_answer").multiselect("getChecked").map(function(){
      return this.value;
  }).get();
  correct_answer.each(function(correct_ans, index){
    $('.ans_radio')[correct_ans - 1].checked = true;
  });
}

submit_quiz = function () {
  var question_form = $('#questionnaire_question_form');
  if(question_form.length > 0) {
    var question_type_field = $('#questionnaire_question_form #question_type').first();
    var save_questionnaire_field = $('#questionnaire_question_form #save_questionnaire').first();
    var page_number = $('#questionnaire_question_form #page_number').first();
    var current_page_number = $('#questionnaire_question_form #current_page_number').first();
    question_type_field.val('');
    page_number.val('');
    current_page_number.val('');
    save_questionnaire_field.val('true');
    jQuery('#submit_question_form').click();
    jQuery('#submit_new_questionnaire').click();
  } else {
    jQuery('#submit_new_questionnaire').submit();
  }
}

set_fill_blanks_properties = function () {
    form = $('#question_properties')[0];
    set_answer_text(form.no_of_answers, fill_blanks_answer_text, 'Please enter an acceptable answer here');
    change_numbering(form.numbering);
    $('#multiple_choice_properties_popup').hide();
}

set_yesno_properties = function() {
    form = $('#question_properties')[0];
    set_radio_correct_answer(form.correct_answer);
    change_numbering(form.numbering);
    shuffle_answer_sel = form.shuffle_answer;
    $('#question_shuffle_answers').value = shuffle_answer_sel[shuffle_answer_sel.selectedIndex].value;
    $('#multiple_choice_properties_popup').hide();
}

cancelInlineEdit = function(element2Remove, element2Show) {
  $('#'+element2Remove).remove();
  $('#'+element2Show).show();
}

question_text_on_blur = function (text_field, prompt){
  if(text_field.value == '') {this.value = 'Please enter your answer here';};
}

question_text_on_focus = function (text_field, prompt) {
    if(!prompt) {
        prompt = 'Please enter your answer here'
    }

    if(text_field.value == prompt) {
      text_field.value = '';
      text_field.removeClassName('prompt');
    }
}

badge_popup = function(event) {
  var selectedBadge = jQuery('#certification_badge_type').val();
  if(selectedBadge == '1' || selectedBadge == '2' || selectedBadge == '3') {
      jQuery('#badge_' + selectedBadge).addClass('selected');
  }
  jQuery('#select_badge_popup').css({'top': jQuery(event).position().top + 8});
  jQuery('#select_badge_popup').show();
}

badge_selection = function(event) {
  jQuery('.badge').not(jQuery(event)).removeClass('selected');
  jQuery(event).toggleClass('selected');
}

submit_badge = function() {
  var selectedBadge = jQuery('#select_badge_popup .badge.selected');
  if(selectedBadge.length > 0) {
    select_badge(selectedBadge.attr('data-badge-number').toString());
  } else {
    select_badge('');
  }
  jQuery('#select_badge_popup').hide();
}

select_badge = function (number) {
  if(number.toString().length > 0) {
  $('#certification_badge_type').val(number);
  jQuery('#badge_placeholder').html('');
  jQuery('#badge_placeholder').append(jQuery('<img>', {src: '/images/badges/' + number.toString() + '.png'}));
  } else {
     $('#certification_badge_type').val('');
     jQuery('#badge_placeholder').html('None');
  }
}

certificate_popup = function(event,url) {
  if(jQuery('#select_certificate_template_popup').parent().attr('id') == 'new_course_panel') {
      jQuery('#select_certificate_template_popup').css({'top': jQuery(event).position().top + 60});
  } else {
    if(jQuery("#advanced_properties").is(":visible")) {
        var right_position = '250px';
    } else {
        var right_position = '2px';
    }
    jQuery('#select_certificate_template_popup').css({'top': jQuery(event).position().top + 18, 'right': right_position});
  }
  jQuery("#upload_container_certificate").show();
  jQuery('#select_certificate_template_popup').show();
}

renderPages = function(pdf,page_num,elem_id) {
  pdf.getPage(page_num).then(function(page) {
    var scale = 1;
    var viewport = page.getViewport({ scale: scale, });

    var canvas = document.createElement('canvas');
    var context = canvas.getContext('2d');
    canvas.height = viewport.height;
    canvas.width = viewport.width;

    var renderContext = {
     canvasContext: context,
     viewport: viewport
    };
    document.getElementById(elem_id).append(canvas);
    page.render(renderContext);
  });
}

myfunction = function(event) {
  jQuery('#category_name').val(jQuery(event).val())
}

certificate_submit = function() {
  var template_preview = jQuery('#select_certificate_template_popup #certificate_content_preview');
  if(template_preview.length > 0) {
    jQuery('#certificate_thumbnail_placeholder').css('max-width','102px')
    jQuery('input.certificate_template_id_field').val(jQuery('#certificate_content_preview').attr('data-certificate-template-id'));
    jQuery('#certificate_thumbnail_placeholder').html(jQuery('#certificate_content_preview'));
  }
  jQuery('#select_certificate_template_popup').hide();
}

load_certification = function(url,id) {
  pdfjs.getDocument(url).promise.then(function(pdf) {
    for (var i = 1; i <= pdf.numPages; i++) {
      renderPages(pdf,i,id)
    }
  });
}

mySearch = function() {
  var input, filter, table, tr, td, i, txtValue;
  input = document.getElementById('search_container_tour');
  filter = input.value.toUpperCase();
  table = document.getElementById('mytable');
  tr = table.getElementsByTagName("tr");
  for (i = 0; i < tr.length; i++) {
    td = tr[i].getElementsByTagName("td")[0];
    if (td) {
      txtValue = td.textContent || td.innerText;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        tr[i].style.display = "";
      } else {
        tr[i].style.display = "none";
      }
    }
  }
}

featuredSearch = function() {
  var input, filter, ul, li, div, i, txtValue;
  input = document.getElementById('Product_featured_item_search');
  filter = input.value.toUpperCase();
  ul = document.getElementById('Product_available_list');
  li = ul.getElementsByTagName("li");
  for (i = 0; i < li.length; i++) {
    div = li[i].getElementsByTagName("div")[0];
    if (div) {
      txtValue = div.textContent || div.innerText;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        li[i].style.display = "";
      } else {
        li[i].style.display = "none";
      }
    }
  }
}

drag = function() {
  Sortable.create(Product_selected_list, {
      handle: '.sort_handle', // handle's class
      animation: 200
  });
}

dragging = function() {
  document.getElementById("product_draggable_item").click();
}

change_view_type = function (element) {
  var elem_id = jQuery('#'+element.id+' :selected').attr("value")+'_view';
  jQuery('#'+elem_id)[0].click();
}

printReceipt = function (divId){
  var printContents = document.getElementById(divId).innerHTML;
  var originalContents = document.body.innerHTML;
  document.body.innerHTML = "<html><head><title></title></head><body>" + printContents + "</body>";
  jQuery('#print').hide();
  jQuery('#close_order_preview').hide();
  window.print();
  document.body.innerHTML = originalContents;
}

submit_page = function (e,value,elem_id,url) {
  if(e.keyCode === 13){
    var link = jQuery('#' + elem_id);
    link.attr("href",url + '&page=' + encodeURIComponent(value));
    link[0].click();
  }
}


changeSelectMenu = function(event, item) {
  jQuery(this).trigger('change', item);
};