(function($) {
    $.fn.property_slider = function(options) {
        var defaults = {
                generalPropertiesCont   : 'general_properties',
                advancedPropertiesCont  : 'advanced_properties',
                backButtonCont: 'back_button_container',
                advancedButtonCont: 'advanced_button_container'
            },
            settings = $.extend(defaults, options);

        return this.each(function() {
            var slider = new PropertySlider(this, settings);
            slider.init();
        });
    };

    var PropertySlider = function(el, options) {
        console.log("property_slider settings")
        console.log(el);
        console.log(el.id);
        var self = this,
            el = el,
            $el = $('#'+el.id),
            $generalPropCont = $el.find('#' + options.generalPropertiesCont),
            $backButtonCont = $el.find('#' + options.backButtonCont),
            $advancedPropCont = $el.find('#' + options.advancedPropertiesCont),
            $advancedButtonCont = $el.find('#' + options.advancedButtonCont),
            $showAdvancedButton = $el.find('#more_properties'),
            $showGeneralButton = $el.find('#less_properties'),
            $innerLightboxOverlay = $('#inner_lightbox_overlay');
            console.log($advancedPropCont);

        var showSectionWithErrors = function() {
            if($el.find('.errors').length > 0) {
                if($generalPropCont.find('.errors').length > 0) {
                    $advancedButtonCont.show();
                    $advancedPropCont.hide();
                    $innerLightboxOverlay.hide();
                    $backButtonCont.hide();
                } else if($advancedPropCont.find('.errors').length > 0) {
                    $backButtonCont.show();
                    $advancedPropCont.show();
                    $innerLightboxOverlay.show();
                    $advancedButtonCont.hide();
                }
            }
        }

        this.init = function() {
            $el.show();
            $generalPropCont.show();
            $advancedButtonCont.show();
            $advancedPropCont.hide();
            $innerLightboxOverlay.hide();
            $backButtonCont.hide();
            showSectionWithErrors();
        }

        $showAdvancedButton.on('click', function(e) {
            e.preventDefault();
            $advancedPropCont.show();
            $backButtonCont.show();
            $innerLightboxOverlay.show();
            $advancedButtonCont.hide();
        });

        $showGeneralButton.on('click', function(e) {
            e.preventDefault();
            $advancedPropCont.hide();
            $innerLightboxOverlay.hide();
            $backButtonCont.hide();
            $advancedButtonCont.show();
        });

        $advancedPropCont.on('show', function(e) {
            if(e.target.id == this.id){
                setTimeout(function(){
                    if($advancedPropCont.height() > $el.height()){
                        jQuery(".content_player").height(($advancedPropCont.height() + 15) +"px");
                    }
                }, 200);
            } else {
                resizeLeftSection();
            }
        });

        $advancedPropCont.on('hide', function(e) {
            if(e.target.id == this.id){
                setTimeout(function(){
                    if($advancedPropCont.height() > $el.height()){
                        jQuery(".content_player").height(($advancedPropCont.height()) +"px");
                    }
                }, 200);
            } else {
                resizeLeftSection();
            }
        });

        // $advancedPropCont.find('a').live('click', function(e) {
        //     resizeLeftSection();
        // });

        // $generalPropCont.find('a').live('click', function(e) {
        //     resizeLeftSection();
        // });
    };
})(jQuery);